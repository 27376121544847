import { oktaAuth } from "../contexts/UserContext";
import { EYEBALL_IFRAME_ID, ItemType } from "../constants/EyeballConstants";
import { AuthInfo, EyeballPostMsgType, EyeballTrainingType, IframePostMsgBody } from "../types/Eyeball";

export const isGameUsedBall = (itemType: string) =>
  itemType.localeCompare(ItemType.GAME_USED_BALL, "en", { sensitivity: "base" }) === 0;

export const isGameUsedBatOrBrokenBat = (itemType: string) =>
  itemType.localeCompare(ItemType.GAME_USED_BAT, "en", { sensitivity: "base" }) === 0 ||
  itemType.localeCompare(ItemType.GAME_USED_BROKEN_BAT, "en", { sensitivity: "base" }) === 0;

export const sendIframeAuthMsg = async (training?: EyeballTrainingType) => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.AUTH,
    training,
    authInfo,
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeTrainingMsg = async (training: EyeballTrainingType) => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.TRAINING,
    authInfo,
    training,
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeEditMsg = async ({
  sessionAuthenticatorId,
  gamePk,
  scrollToEntityType,
  scrollToIndex,
  training,
}: Omit<IframePostMsgBody, "type" | "authInfo">) => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.EDIT,
    authInfo,
    sessionAuthenticatorId,
    gamePk,
    scrollToEntityType,
    scrollToIndex,
    training,
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeResetModalsMsg = async () => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.RESET_MODALS,
    authInfo: authInfo,
  };

  sendIframeMsg(postMsgBody);
};

const getAuthInfo = async (): Promise<AuthInfo> => {
  const authenticated = await oktaAuth.isAuthenticated();

  if (authenticated) {
    const authState = oktaAuth.authStateManager.getAuthState();
    return {
      email: authState?.idToken?.claims.email ?? "",
      accessToken: authState?.accessToken?.accessToken ?? "",
      idToken: authState?.idToken?.idToken ?? "",
      idTokenIssuedAtInSeconds: authState?.idToken?.claims.iat ?? 0,
    };
  }

  throw new Error("Error getting token info. Please try again or contact MLB.");
};

const sendIframeMsg = (postMsgBody: IframePostMsgBody) => {
  const iframe = document.getElementById(EYEBALL_IFRAME_ID) as HTMLIFrameElement | null;
  iframe?.contentWindow?.postMessage(postMsgBody, window.MLBBest.envVariables.EYEBALL_APP_URL ?? "");
};
