import { OktaAuthOptions } from "@okta/okta-auth-js";

const CLIENT_ID = window.MLBBest.envVariables.OKTA_CLIENT_ID;
const ISSUER = window.MLBBest.envVariables.OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = window.MLBBest.envVariables.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

export default {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  tokenManager: {
    autoRenew: true,
  },
  services: {
    autoRenew: false,
    autoRemove: false,
  },
} as OktaAuthOptions;
