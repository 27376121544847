export enum RouteConstants {
  LOGIN = "/login",
  LOGIN_CALLBACK = "/login/callback",
  EYEBALL_GAMES = "/eyeball/games",
  EYEBALL_GAME_VIEWER = "/eyeball/gameViewer/:gamePk",
  TRAINING = "/training",
  TRAINING_CANNED = "/training/canned",
  TRAINING_LIVE = "/training/live",
  TRAINING_REVIEW = "/training/review",
  TRAINING_GAME_REVIEW = "/training/review/:gamePk",
  TRAINING_CANNED_GAME = "/training/canned/:gamePk",
  TRAINING_LIVE_GAME = "/training/live/:gamePk",
  INVENTORY = "/inventory",
  INVENTORY_HOLOGRAM = "/inventory/:hologram",
  MILESTONES = "/milestones",
  SETTINGS = "/settings",
  BASE = "/",
}

export default RouteConstants;
