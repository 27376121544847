import { ValueOpt } from "best-common-react";
import { StateCreator, create } from "zustand";
import { EyeballEntityType } from "../constants/EyeballConstants";
import { EyeballTrainingType } from "../types/Eyeball";
import { sendIframeEditMsg, sendIframeResetModalsMsg } from "../utils/EyeballUtil";

type EnterEditModePayload = {
  sessionAuthenticatorId: number;
  gamePk: number;
  eyeballEntityType: EyeballEntityType;
  editItemId: number;
  itemOrder: number;
  training: EyeballTrainingType.REVIEW | undefined;
};

export const ALL_AUTHENTICATORS_FILTER_OPTION: ValueOpt<number> = {
  value: -1,
  label: "All Authenticators",
};

type State = {
  iframeUrl: string;
  editingSessionAuthenticatorId: number;
  editMode: boolean;
  editItemId: number;
  eyeballSyncing: boolean;
  refreshReview: boolean;
  selectedAuthenticator: ValueOpt<number>;
  training?: EyeballTrainingType.REVIEW;
};

type Actions = {
  enterEditMode: (enterEditModePayload: EnterEditModePayload) => Promise<void>;
  exitEditMode: () => Promise<void>;
  setEyeballSyncing: (eyeballSyncing: boolean) => void;
  setRefreshReview: (refreshReview: boolean) => void;
  setSelectedAuthenticator: (authenticator: ValueOpt<number>) => void;
  setTraining: (training?: EyeballTrainingType.REVIEW) => void;
};

type GameReviewStore = State & Actions;

const initialState: State = {
  iframeUrl: window.MLBBest.envVariables.EYEBALL_APP_URL ?? "",
  editingSessionAuthenticatorId: -1,
  editMode: false,
  editItemId: -1,
  eyeballSyncing: false,
  refreshReview: false,
  selectedAuthenticator: ALL_AUTHENTICATORS_FILTER_OPTION,
  training: undefined,
};

const createGameReviewStore: StateCreator<GameReviewStore, [], [], GameReviewStore> = (set) => ({
  ...initialState,
  enterEditMode: async (enterEditModePayload: EnterEditModePayload) => {
    set({ editMode: true, editItemId: enterEditModePayload.editItemId });
    await sendIframeEditMsg({
      sessionAuthenticatorId: enterEditModePayload.sessionAuthenticatorId,
      gamePk: enterEditModePayload.gamePk,
      scrollToEntityType: enterEditModePayload.eyeballEntityType,
      scrollToIndex: enterEditModePayload.itemOrder,
      training: enterEditModePayload.training,
    });
  },
  exitEditMode: async () => {
    set({ editMode: false, editItemId: -1, eyeballSyncing: false, refreshReview: false }),
      await sendIframeResetModalsMsg();
  },
  setEyeballSyncing: (eyeballSyncing: boolean) => set({ eyeballSyncing }),
  setRefreshReview: (refreshReview: boolean) => set({ refreshReview }),
  setSelectedAuthenticator: (authenticator: ValueOpt<number>) => set({ selectedAuthenticator: authenticator }),
  setTraining: (training?: EyeballTrainingType.REVIEW) => set({ training }),
});

const useGameReviewStore = create<GameReviewStore>()((...a) => ({
  ...createGameReviewStore(...a),
}));

export default useGameReviewStore;
