import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "best-common-react/lib/styles/bcr.css";
import "./index.css";
import WrapperProvider from "./WrapperProvider";

const container = document.getElementById("mlb-authentication-app");
const root = createRoot(container!);
root.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
);
